import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import HomepageDark from "./pages/HomepageDark";
import Bloglist from "./pages/Bloglist";
import BlogDetails from "./pages/BlogDetails";
import WorkDetails from "./pages/WorkDetails";
import Gallery from "./pages/PhotoAlbum";
import "./App.scss";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <HomepageDark />
        </Route>
        <Route path="/blogs" component={Bloglist} exact />
        <Route path="/blogs/blog-details/:id/:title" component={BlogDetails} />
        <Route path="/projects/proj-details/:id/:title" component={WorkDetails} />
        <Route path="/album" component={Gallery} exact />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
