import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "PhD (Integrated PhD) in Bioinformatics",
    years: "2005 - 2010",
    content:
      "Developed a HMM-HMM profile alignment program comparable to HHSearch with better function annotation. Developed computational tools for predicting host-parasite protein-protein interactions",
  },
  {
    id: 2,
    title: "MS (Integrated PhD) in Life Sciences",
    years: "2002 - 2005",
    content:
      "Coursework of 64 credits spanning everything from ecology/evolution to Biophysics. Completed two semester projects, both leading to first author publications.",
  },
  {
    id: 3,
    title: "B.Sc (Hons) in Biochemistry",
    years: "1999 - 2002",
    content:
      "First class, coursework spanning biochemistry to molecular biology.",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Senior Scientist, AI/ML in Enzymology, Solugen Inc",
    years: "Feb 2022 - Dec 2022",
    content:
      "Enzyme re-engineering using ESM and graph networks for substrate change and improving enzyme efficiency.",
  },
  {
    id: 2,
    title: "Vice-President, PVAI (Data science R&D), Genpact Inc",
    years: "Jun 2018 - Feb 2022",
    content:
      "Architected the first in class AI in pharamcovigilance SaaS product. Deployed for fast COVID side-effect processing at MHRA (UK).",
  },
  {
    id: 3,
    title: "Scientist, Dept of Bioengineering, Univ of Michigan",
    years: "Mar 2017 - Jun 2018",
    content:
      "Developed ML methods to predict tumor survival based on experimental and computational metabolic profiles.",
  },
  {
    id: 4,
    title: "Head Scientist, Computational Biology,  GVKBIO Pvt Ltd",
    years: "Feb 2015 - Aug 2016",
    content:
      "Delivered > 10 projects spanning pharamacogenomics to antibody-drug conjugates. Established AI/NLP services.",
  },
  {
    id: 5,
    title: "Scientist, Biochemistry and Molecular Biology, Univ of Georgia",
    years: "Oct 2010 - Dec 2014",
    content:
      "Discovered a novel peptide flip in protein kinases with implications for drug design.",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
