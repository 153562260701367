import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import Header from "../components/layouts/Header";
import { doc, getDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLfwdP-1UrL2G95J6uogDTHpDG0v4Vu2g",
  authDomain: "hospitalpv.firebaseapp.com",
  databaseURL: "https://hospitalpv-default-rtdb.firebaseio.com",
  projectId: "hospitalpv",
  storageBucket: "hospitalpv.appspot.com",
  messagingSenderId: "567611223299",
  appId: "1:567611223299:web:b5e7bcaa3e3179f87e4d36",
  measurementId: "G-HE4D0NTP4P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firedb = getFirestore(app);

function BlogDetails(props) {
  //const [content, setContent] = useState("");
  document.body.classList.add("dark");
  const blogId = props.match.params.id;
  const blogFile = props.match.params.title;
  const { id, category, title, date, author, image, filesource, did } = props.location.state
  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  return (
    <>
      <Header
        logoSource="/images/logo.svg"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
            <div className="blog-single shadow-dark p-30">
	       <h3>{title}</h3>
	       <div style={{justifyContent: 'space-evenly'}}>
	          <div style={{float: "left", width:"25%"}}>Published : {date}</div>
	          <div style={{float: "left", width:"50%"}}>Tags : {category}</div>
	          <div style={{float: "left", width:"25%"}}>Author: {author}</div></div>
	    </div>
            <div className="spacer" data-height="20"></div>
            <div className="blog-single shadow-dark p-30">
              <Markdown>{filesource}</Markdown>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default BlogDetails;
