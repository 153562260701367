import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Publication from "../elements/Publication";

const educationData = [
  {
    PMID: 32411371,
    title: "Common biochemical properties of metabolic genes recurrently dysregulated in tumors",
    authors: "Oruganty K, Campit SE, Mamde S, Lyssiotis CA, Chandrasekaran S.",
    citation: "Cancer Metab. 2020 May 8;8:5. doi: 10.1186/s40170-020-0211-1. eCollection 2020.",
    journal: "Cancer Metab",
    year: 2020,
    pmcid: "PMC7206696",
    doi: "10.1186/s40170-020-0211-1"
  },
  {
    PMID: 30420606,
    title: "The entropic force generated by intrinsically disordered segments tunes protein function",
    authors: "Keul ND, Oruganty K, Schaper Bergman ET, Beattie NR, McDonald WE, Kadirvelraj R, Gross ML, Phillips RS, Harvey SC, Wood ZA.",
    citation: "Nature. 2018 Nov;563(7732):584-588. doi: 10.1038/s41586-018-0699-5. Epub 2018 Nov 12.",
    journal: "Nature",
    year: 2018,
    pmcid: "PMC6415545",
    doi: "10.1038/s41586-018-0699-5"
  },
  {
    PMID: 26738562,
    title: "Identification and classification of small molecule kinases: insights into substrate recognition and specificity",
    authors: "Oruganty K, Talevich EE, Neuwald AF, Kannan N.",
    citation: "BMC Evol Biol. 2016 Jan 6;16:7. doi: 10.1186/s12862-015-0576-x.",
    journal: "BMC Evol Biol",
    year: 2016,
    pmcid: "PMC4702295",
    doi: "10.1186/s12862-015-0576-x"
  },
  {
    PMID: 27513745,
    title: "Correction: Hydrophobic Core Variations Provide a Structural Framework for Tyrosine Kinase Evolution and Functional Specialization",
    authors: "Mohanty S, Oruganty K, Kwon A, Byrne DP, Ferries S, Ruan Z, Hanold LE, Katiyar S, Kennedy EJ, Eyers PA, Kannan N.",
    citation: "PLoS Genet. 2016 Aug 11;12(8):e1006265. doi: 10.1371/journal.pgen.1006265. eCollection 2016 Aug.",
    journal: "PLoS Genet",
    year: 2016,
    pmcid: "PMC4981435",
    doi: "10.1371/journal.pgen.1006265"
  },
  {
    PMID: 26925779,
    title: "Hydrophobic Core Variations Provide a Structural Framework for Tyrosine Kinase Evolution and Functional Specialization",
    authors: "Mohanty S, Oruganty K, Kwon A, Byrne DP, Ferries S, Ruan Z, Hanold LE, Katiyar S, Kennedy EJ, Eyers PA, Kannan N.",
    citation: "PLoS Genet. 2016 Feb 29;12(2):e1005885. doi: 10.1371/journal.pgen.1005885. eCollection 2016 Feb.",
    journal: "PLoS Genet",
    year: 2016,
    pmcid: "PMC4771162",
    doi: "10.1371/journal.pgen.1005885"
  },
  {
    PMID: 25790232,
    title: "Inhibiting EGFR dimerization using triazolyl-bridged dimerization arm mimics",
    authors: "Hanold LE, Oruganty K, Ton NT, Beedle AM, Kannan N, Kennedy EJ.",
    citation: "PLoS One. 2015 Mar 19;10(3):e0118796. doi: 10.1371/journal.pone.0118796. eCollection 2015.",
    journal: "PLoS One",
    year: 2015,
    pmcid: "PMC4366150",
    doi: "10.1371/journal.pone.0118796"
  },
  {
    PMID: 25498144,
    title: "Mitochondrial ADCK3 employs an atypical protein kinase-like fold to enable coenzyme Q biosynthesis",
    authors: "Stefely JA, Reidenbach AG, Ulbrich A, Oruganty K, Floyd BJ, Jochem A, Saunders JM, Johnson IE, Minogue CE, Wrobel RL, Barber GE, Lee D, Li S, Kannan N, Coon JJ, Bingman CA, Pagliarini DJ.",
    citation: "Mol Cell. 2015 Jan 8;57(1):83-94. doi: 10.1016/j.molcel.2014.11.002. Epub 2014 Dec 11.",
    journal: "Mol Cell",
    year: 2015,
    pmcid: "PMC4289473",
    doi: "10.1016/j.molcel.2014.11.002"
  },
  {
    PMID: 25583260,
    title: "The Tribbles 2 (TRB2) pseudokinase binds to ATP and autophosphorylates in a metal-independent manner",
    authors: "Bailey FP, Byrne DP, Oruganty K, Eyers CE, Novotny CJ, Shokat KM, Kannan N, Eyers PA.",
    citation: "Biochem J. 2015 Apr 1;467(1):47-62. doi: 10.1042/BJ20141441.",
    journal: "Biochem J",
    year: 2015,
    pmcid: "PMC4844368",
    doi: "10.1042/BJ20141441"
  },
  {
    PMID: 25799139,
    title: "Co-conserved MAPK features couple D-domain docking groove to distal allosteric sites via the C-terminal flanking tail",
    authors: "Nguyen T, Ruan Z, Oruganty K, Kannan N.",
    citation: "PLoS One. 2015 Mar 23;10(3):e0119636. doi: 10.1371/journal.pone.0119636. eCollection 2015.",
    journal: "PLoS One",
    year: 2015,
    pmcid: "PMC4370755",
    doi: "10.1371/journal.pone.0119636"
  },
  {
    PMID: 24143133,
    title: "Deciphering the structural basis of eukaryotic protein kinase regulation",
    authors: "Meharena HS, Chang P, Keshwani MM, Oruganty K, Nene AK, Kannan N, Taylor SS, Kornev AP.",
    citation: "PLoS Biol. 2013 Oct;11(10):e1001680. doi: 10.1371/journal.pbio.1001680. Epub 2013 Oct 15.",
    journal: "PLoS Biol",
    year: 2013,
    pmcid: "PMC3797032",
    doi: "10.1371/journal.pbio.1001680"
  },
  {
    PMID: 23499783,
    title: "Evolutionary variation and adaptation in a conserved protein kinase allosteric network: implications for inhibitor design",
    authors: "Oruganty K, Kannan N.",
    citation: "Biochim Biophys Acta. 2013 Jul;1834(7):1322-9. doi: 10.1016/j.bbapap.2013.02.040. Epub 2013 Mar 14.",
    journal: "Biochim Biophys Acta",
    year: 2013,
    pmcid: null,
    doi: "10.1016/j.bbapap.2013.02.040"
  },
  {
    PMID: 23277537,
    title: "Identification of a hidden strain switch provides clues to an ancient structural mechanism in protein kinases",
    authors: "Oruganty K, Talathi NS, Wood ZA, Kannan N.",
    citation: "Proc Natl Acad Sci U S A. 2013 Jan 15;110(3):924-9. doi: 10.1073/pnas.1207104110. Epub 2012 Dec 31.",
    journal: "Proc Natl Acad Sci U S A",
    year: 2013,
    pmcid: "PMC3549070",
    doi: "10.1073/pnas.1207104110"
  },
  {
    PMID: 22889905,
    title: "Design principles underpinning the regulatory diversity of protein kinases",
    authors: "Oruganty K, Kannan N.",
    citation: "Philos Trans R Soc Lond B Biol Sci. 2012 Sep 19;367(1602):2529-39. doi: 10.1098/rstb.2012.0015.",
    journal: "Philos Trans R Soc Lond B Biol Sci",
    year: 2012,
    pmcid: "PMC3415841",
    doi: "10.1098/rstb.2012.0015"
  },
  {
    PMID: 21729312,
    title: "AlignHUSH: alignment of HMMs using structure and hydrophobicity information",
    authors: "Krishnadev O, Srinivasan N.",
    citation: "BMC Bioinformatics. 2011 Jul 5;12:275. doi: 10.1186/1471-2105-12-275.",
    journal: "BMC Bioinformatics",
    year: 2011,
    pmcid: "PMC3228556",
    doi: "10.1186/1471-2105-12-275"
  },
  {
    PMID: 21310175,
    title: "Prediction of protein-protein interactions between human host and a pathogen and its application to three pathogenic bacteria",
    authors: "Krishnadev O, Srinivasan N.",
    citation: "Int J Biol Macromol. 2011 May 1;48(4):613-9. doi: 10.1016/j.ijbiomac.2011.01.030. Epub 2011 Feb 16.",
    journal: "Int J Biol Macromol",
    year: 2011,
    pmcid: null,
    doi: "10.1016/j.ijbiomac.2011.01.030"
  },
  {
    PMID: 19519480,
    title: "Evolutionary divergence of Plasmodium falciparum: sequences, protein-protein interactions, pathways and processes",
    authors: "Tyagi N, Swapna LS, Mohanty S, Agarwal G, Gowri VS, Anamika K, Priya ML, Krishnadev O, Srinivasan N.",
    citation: "Infect Disord Drug Targets. 2009 Jun;9(3):257-71. doi: 10.2174/1871526510909030257.",
    journal: "Infect Disord Drug Targets",
    year: 2009,
    pmcid: null,
    doi: "10.2174/1871526510909030257"
  },
  {
    PMID: 20023722,
    title: "Prediction of protein-protein interactions between Helicobacter pylori and a human host",
    authors: "Tyagi N, Krishnadev O, Srinivasan N.",
    citation: "Mol Biosyst. 2009 Dec;5(12):1630-5. doi: 10.1039/b906543c. Epub 2009 Sep 28.",
    journal: "Mol Biosyst",
    year: 2009,
    pmcid: null,
    doi: "10.1039/b906543c"
  },
  {
    PMID: 19032159,
    title: "A data integration approach to predict host-pathogen protein-protein interactions: application to recognize protein interactions between human and a malarial parasite",
    authors: "Krishnadev O, Srinivasan N.",
    citation: "In Silico Biol. 2008;8(3-4):235-50.",
    journal: "In Silico Biol",
    year: 2008,
    pmcid: null,
    doi: null
  },
  {
    PMID: 17380509,
    title: "Strategies for the effective identification of remotely related sequences in multiple PSSM search approach",
    authors: "Gowri VS, Tina KG, Krishnadev O, Srinivasan N.",
    citation: "Proteins. 2007 Jun 1;67(4):789-94. doi: 10.1002/prot.21356.",
    journal: "Proteins",
    year: 2007,
    pmcid: null,
    doi: "10.1002/prot.21356"
  },
  {
    PMID: 16381855,
    title: "MulPSSM: a database of multiple position-specific scoring matrices of protein domain families",
    authors: "Gowri VS, Krishnadev O, Swamy CS, Srinivasan N.",
    citation: "Nucleic Acids Res. 2006 Jan 1;34(Database issue):D243-6. doi: 10.1093/nar/gkj043.",
    journal: "Nucleic Acids Res",
    year: 2006,
    pmcid: "PMC1347406",
    doi: "10.1093/nar/gkj043"
  },
  {
    PMID: 16080147,
    title: "A graph spectral analysis of the structural similarity network of protein chains",
    authors: "Krishnadev O, Brinda KV, Vishveshwara S.",
    citation: "Proteins. 2005 Oct 1;61(1):152-63. doi: 10.1002/prot.20532.",
    journal: "Proteins",
    year: 2005,
    pmcid: null,
    doi: "10.1002/prot.20532"
  },
  {
    PMID: 16105176,
    title: "Interaction preferences across protein-protein interfaces of obligatory and non-obligatory components are different",
    authors: "De S, Krishnadev O, Srinivasan N, Rekha N.",
    citation: "BMC Struct Biol. 2005 Aug 16;5:15. doi: 10.1186/1472-6807-5-15.",
    journal: "BMC Struct Biol",
    year: 2005,
    pmcid: "PMC1201154",
    doi: "10.1186/1472-6807-5-15"
  },
  {
    PMID: 15980440,
    title: "PRODOC: a resource for the comparison of tethered protein domain architectures with in-built information on remotely related domain families",
    authors: "Krishnadev O, Rekha N, Pandit SB, Abhiman S, Mohanty S, Swapna LS, Gore S, Srinivasan N.",
    citation: "Nucleic Acids Res. 2005 Jul 1;33(Web Server issue):W126-9. doi: 10.1093/nar/gki474.",
    journal: "Nucleic Acids Res",
    year: 2005,
    pmcid: "PMC1160235",
    doi: "10.1093/nar/gki474"
  }
];

function Publications() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Publications" />
        <div className="row">
          <div className="col-md-20">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Publication key={education.PMID} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default Publications;
