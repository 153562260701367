import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLfwdP-1UrL2G95J6uogDTHpDG0v4Vu2g",
  authDomain: "hospitalpv.firebaseapp.com",
  databaseURL: "https://hospitalpv-default-rtdb.firebaseio.com",
  projectId: "hospitalpv",
  storageBucket: "hospitalpv.appspot.com",
  messagingSenderId: "567611223299",
  appId: "1:567611223299:web:b5e7bcaa3e3179f87e4d36",
  measurementId: "G-HE4D0NTP4P"
};

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const firedb = getFirestore(app);


function Blogs() {
     //console.log(allBlogs);
     const [posts, setPosts] = useState([]);
     const [currentPage, setCurrentPage] = useState(1);
     const [postsPerPage] = useState(3);

     const fetchPost = async () => {

        await getDocs(collection(firedb, "blogs"))
            .then((querySnapshot)=>{
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), did:doc.id}));
                setPosts(newData);
                console.log(posts, newData);
            })
    }

    useEffect(()=>{
        fetchPost();
    }, [])

     const indexOfLastPost = currentPage * postsPerPage;
     const indexOfFirstPost = indexOfLastPost - postsPerPage;
     const allBlogs = posts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <section id="blog">
      <div className="container">
        <Pagetitle title="Latest Blogs" />
        <div className="row blog-wrapper">
          {allBlogs.map((blogItem) => (
            <div className="col-md-4" key={blogItem.id}>
              <Blog blogData={blogItem} />
            </div>
          ))}
        </div>
        <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <Link to="/blogs" className="btn btn-default">
            Show all blogs
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
