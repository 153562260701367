import React, { useState, useEffect } from "react";
import Header from "../components/layouts/Header";
import Blog from "../components/elements/Blog";
import Pagination from "../components/elements/Pagination";

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLfwdP-1UrL2G95J6uogDTHpDG0v4Vu2g",
  authDomain: "hospitalpv.firebaseapp.com",
  databaseURL: "https://hospitalpv-default-rtdb.firebaseio.com",
  projectId: "hospitalpv",
  storageBucket: "hospitalpv.appspot.com",
  messagingSenderId: "567611223299",
  appId: "1:567611223299:web:b5e7bcaa3e3179f87e4d36",
  measurementId: "G-HE4D0NTP4P"
};

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const firedb = getFirestore(app);

const allBlogsnew = [
  {
    id: 1,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Bolby",
    category: "Reviews",
  },
  {
    id: 2,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Bolby",
    category: "Reviews",
  },
  {
    id: 3,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Bolby",
    category: "Reviews",
  }
]

//var allBlogs = [];

//async function getBlogs(firedb){
//    const blogcoll = collection(firedb, 'blogs');
//    const blogs = await getDocs(blogcoll);
//    const allblb = blogs.docs.map(doc => doc.data());
//    return allblb
//}


function Bloglist() {
     document.body.classList.add("dark");
     //allBlogs = getBlogs(firedb)
     console.log("This tatti here");
     //console.log(allBlogs);
     const [posts, setPosts] = useState([]);
     const [currentPage, setCurrentPage] = useState(1);
     const [postsPerPage] = useState(6);

     const fetchPost = async () => {
       
        await getDocs(collection(firedb, "blogs"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), did:doc.id}));
                setPosts(newData);                
                console.log(posts, newData);
            })
    }
   
    useEffect(()=>{
        fetchPost();
    }, [])

     const indexOfLastPost = currentPage * postsPerPage;
     const indexOfFirstPost = indexOfLastPost - postsPerPage;
     const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

     const paginate = (e, pageNumber) => {
       e.preventDefault();
       setCurrentPage(pageNumber);
     };

     const [toggleMenu, setToggleMenu] = useState(false);

     const headerToggler = (e) => {
       e.preventDefault();
       setToggleMenu(!toggleMenu);
     };

     document.addEventListener("click", function (e) {
       if (e.target.closest(".content")) {
         setToggleMenu(false);
       }
     });

  return (
    <>
      <Header
        logoSource="/images/logo.svg"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
            <div className="row blog-wrapper fix-spacing">
              {currentPosts.map((blogItem) => (
                <div className="col-md-6" key={blogItem.id}>
                  <Blog blogData={blogItem} />
                </div>
              ))}
            </div>
            <div className="spacer" data-height="50"></div>
            {!(posts.length > postsPerPage) ? null : (
              <Pagination
                itemsPerPage={postsPerPage}
                totalItems={posts.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default Bloglist;
