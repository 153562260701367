import React, { useState, useEffect } from "react";
import Header from "../components/layouts/Header";
import ImageGallery from "react-image-gallery";

const photos = [
    {original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5476.JPG?alt=media&token=fda83b44-3c25-4131-b179-723d152ba0aa", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5476.JPG.thumb.png?alt=media&token=59f509e2-b4b3-49da-aa10-56b7a74e7795" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5496.JPG?alt=media&token=7553ae6a-921c-4408-bc99-5b1e2536e5a6", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5496.JPG.thumb.png?alt=media&token=79b9fe32-4770-4a9b-b1cd-78491cd0a321" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5511.JPG?alt=media&token=805dea76-0f9e-48fb-9daa-1727a5fface1", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5511.JPG.thumb.png?alt=media&token=318afae6-ee9c-4189-97cc-98a7cd38aea8" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5518.JPG?alt=media&token=19c62787-139b-4e6a-a512-0b35690c047f", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5518.JPG.thumb.png?alt=media&token=d4c2ce65-9128-4364-b874-6c551c79ee12" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5534.JPG?alt=media&token=2e4d5913-f001-44b7-9d83-ec3aa898fb9d", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5534.JPG.thumb.png?alt=media&token=f7259512-57d2-45a2-bfb8-3c76f8f05f72" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5538.JPG?alt=media&token=36acda85-51fe-43d3-b754-3d7fdeaaa16b", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5538.JPG.thumb.png?alt=media&token=e8a56b05-e305-4457-ab06-b9a499846fc5" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5543.JPG?alt=media&token=bd5dd277-4ce4-4018-9d98-f7ec5f2812b6", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5543.JPG.thumb.png?alt=media&token=2e59b11e-b39a-4e7c-9adb-558df66a7e0b" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5553.JPG?alt=media&token=e0b5b838-9417-412a-a748-ec3f55524721", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5553.JPG.thumb.png?alt=media&token=0e3e7e7f-0e63-4710-b8eb-74cfe8cc1690" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5565.JPG?alt=media&token=89e68c19-5bf5-4cae-a005-4b16db160aec", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5565.JPG.thumb.png?alt=media&token=cadf371c-6d64-4284-9dbe-bd00e50c7b44" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5576.JPG?alt=media&token=98084210-982f-47cd-802c-9cb5a3c76cbf", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5576.JPG.thumb.png?alt=media&token=ce3eb96d-8b10-463c-a7b7-11642dad8435" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5583.JPG?alt=media&token=95fa6144-6515-4012-bb6d-89789e8daae7", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5583.JPG.thumb.png?alt=media&token=fe6d0cef-1bcb-4065-ba8e-31b820333e99" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5605.JPG?alt=media&token=7abae170-8cc7-4916-a99c-190046f1d8f4", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5605.JPG.thumb.png?alt=media&token=cc5dba49-6070-4147-a495-53aad352490f" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5647.JPG?alt=media&token=22828f0c-ff0b-47b5-99bb-73ce36758064", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5647.JPG.thumb.png?alt=media&token=48eaf51f-a0c7-40ea-949b-0917d23c83bd" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2FIMGP5721.JPG?alt=media&token=5745481b-ccee-4725-ad35-eb5f3f835945", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2FIMGP5721.JPG.thumb.png?alt=media&token=c50f94b1-fbce-4ddc-9e72-dd2e784be58a" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP0291.JPG?alt=media&token=a674a2d5-0985-48c0-9442-24c54c08d273", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP0291.JPG.thumb.png?alt=media&token=5413654f-2750-444d-8442-fdd2476b58fa" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP0323.JPG?alt=media&token=250af6d1-4946-4078-b8d2-85fb77ddd4ce", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP0323.JPG.thumb.png?alt=media&token=5801e71a-e0e5-4b35-8083-37afe9367ee1" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP0332.JPG?alt=media&token=0df6257e-4d20-4c96-b8a0-99a1e2d66671", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP0332.JPG.thumb.png?alt=media&token=4f1174b0-a838-4578-bdf8-a1d42c220223" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP0715.JPG?alt=media&token=d9da29f3-6ba9-48e3-9732-8b510f413f3d", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP0715.JPG.thumb.png?alt=media&token=ffcc260d-76b0-4487-8c8c-141e811b2922" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP0739.JPG?alt=media&token=e8d3ff51-4d05-4987-aa8d-fab9325e11af", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP0739.JPG.thumb.png?alt=media&token=97247610-6281-4ad7-b861-5056847f622f" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP0756.JPG?alt=media&token=99a1ccde-33a4-4733-999a-2764850df6bd", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP0756.JPG.thumb.png?alt=media&token=88ad117c-c8d7-4d2a-acdc-5491ebe0ea2d" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP0771.JPG?alt=media&token=d6669e15-c57e-4f01-bb0e-941f33fd083c", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP0771.JPG.thumb.png?alt=media&token=0c357dc9-44f7-4632-af47-5b9527a27ddc" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP0825.JPG?alt=media&token=63775eea-e9fa-4c73-8d12-192419212ea4", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP0825.JPG.thumb.png?alt=media&token=1505f4c4-f71c-42f2-a9a8-c10f1d07eac6" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP1169.JPG?alt=media&token=f3d01a07-053d-4b1c-adbd-4ca61c5579f8", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP1169.JPG.thumb.png?alt=media&token=46bdd5d7-2eba-466b-8356-7d7283a67288" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP1175.JPG?alt=media&token=41348437-95af-4f27-b95f-02a3d0abe5cf", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP1175.JPG.thumb.png?alt=media&token=2f562816-268e-4283-bdfe-1e299138c668" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP1191.JPG?alt=media&token=0ddbbb69-896d-4ed4-ba70-7a21d8743290", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP1191.JPG.thumb.png?alt=media&token=fa5d8617-e6cd-48ad-aca7-ca07f9aaf7ff" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP1272.JPG?alt=media&token=8b358cb8-d320-471e-9d9c-f6531ac56515", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP1272.JPG.thumb.png?alt=media&token=b8eb88f0-7819-46c2-a27b-455d26fdb9ff" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP1336.JPG?alt=media&token=c1e1f5d5-65da-49ef-be8a-dd2b1383f417", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP1336.JPG.thumb.png?alt=media&token=33a68524-174c-47c0-8329-f97f19688820" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP1347.JPG?alt=media&token=37800637-4a43-488c-89ee-996e8038ed40", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP1347.JPG.thumb.png?alt=media&token=a750550b-853f-4c13-86d5-2764688ec2bc" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP1393.JPG?alt=media&token=06786e4d-d4ee-480c-9b9b-f3a0624693cd", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP1393.JPG.thumb.png?alt=media&token=eabfc8cf-7b56-4931-9a38-a53f0a096040" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP1410.JPG?alt=media&token=5e77febb-69da-4068-8a67-5f3c3bbc7082", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP1410.JPG.thumb.png?alt=media&token=b53640d8-3469-4ceb-a7ce-3f58b999b088" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP2517.JPG?alt=media&token=db330831-6bb1-4096-b1a8-0e03c705eaa3", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP2517.JPG.thumb.png?alt=media&token=aba3d00f-ef40-4e25-9b02-de35fc80cbfb" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP3565.JPG?alt=media&token=ce15219d-1a7a-4bde-8ff2-e889271ca3fa", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP3565.JPG.thumb.png?alt=media&token=664fa017-b0f8-48d0-b91a-d648c40dcbf2" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP3570.JPG?alt=media&token=eedccf65-01dc-41f0-b7e7-0a7e1b3aa59d", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP3570.JPG.thumb.png?alt=media&token=cc0e487b-66aa-46de-9199-72d92b1a8067" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP4017.JPG?alt=media&token=f1a23362-f673-4c2f-bbc3-c3a325000648", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP4017.JPG.thumb.png?alt=media&token=8c5029f4-976e-4198-91d1-cd6537a0cded" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP4687.JPG?alt=media&token=4945aabf-c61b-4fd9-bcab-a5fb187c7e3f", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP4687.JPG.thumb.png?alt=media&token=ef5fec97-9081-492e-850c-f5fc1071f297" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP5350.JPG?alt=media&token=ea5c0443-841a-4d17-9792-90902a8ad27f", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP5350.JPG.thumb.png?alt=media&token=a000b128-cc43-4b70-bc61-4b8e8f76f5a3" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP5412.JPG?alt=media&token=d864bf1b-3347-4541-9fa7-8c19fefea000", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP5412.JPG.thumb.png?alt=media&token=82a55969-a15b-4853-89b9-ebe1bb0f2861" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP5416.JPG?alt=media&token=790b0f9d-46ee-4ab5-8e58-1e35120aded4", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP5416.JPG.thumb.png?alt=media&token=c33c637f-7a4a-4138-b380-592f82d0abc9" },

{original: "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM%2F_IGP7296.JPG?alt=media&token=43c8a618-dc28-442d-ba06-21068a31c70c", thumbnail : "https://firebasestorage.googleapis.com/v0/b/hospitalpv.appspot.com/o/WEB_ALBUM_THUMBS%2F_IGP7296.JPG.thumb.png?alt=media&token=28d586c2-d82f-4a7d-913f-0905bf217091" }
];

function Gallery(){
  // We can add getting file files from firebase and cloud storage here. Add copyright to all images.
  document.body.classList.add("dark");
  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });
  return (
      <>
      <Header
        logoSource="/images/logo.svg"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
      	     <div className="image-gallery-wrapper">
                <ImageGallery items={photos} />
             </div>
	     <p>&copy; 2010-2023 Krishna Dev Oruganty</p>
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default Gallery
