import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Blog from "../elements/Blog";
import Pagetitle from "../elements/Pagetitle";

import { initializeApp } from "firebase/app";
import { getStorage, ref, list, listAll, getDownloadURL } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
function Photos() {
     //console.log(allBlogs);
  const posts = [];
  return (
    <section id="photos">
      <div className="container">
        <Pagetitle title="Latest Photos" />
        <div className="row blog-wrapper">
          {posts.map((blogItem) => (
            <div className="col-md-4" key={blogItem.id}>
              <Blog blogData={blogItem} />
            </div>
          ))}
        </div>
        <div className="text-center">
          <div className="spacer" data-height="30"></div>
          <Link to="/album" className="btn btn-default">
            Show all Photos
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Photos;
